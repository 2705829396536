// Constant
import constants from "./constants/index";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import Note from "../../assets/img/note.svg";
import ExitTerms from "../popup/exitTerms";
import ExitPrivacy from "../popup/exitPrivacy";

const Eligibility = ({
  nextStep,
  handleCancel,
  register,
  setModalShow,
  isPatient,
  errors,
}) => {
  const [indication, setIndication] = useState(false);
  const [insuranceType, setInsuranceType] = useState(false);
  const [qountry, setQountry] = useState(false);
  const [ageeighteen, setAgeeighteen] = useState(false);
  const [patconsent, setPatconsent] = useState(false);
  const [textOptIn, setTextOptIn] = useState(false);
  const [qhippaconsent, setQhippaconsent] = useState(false);
  const [qreadterms, setReadTerms] = useState(false);
  const [role, setRole] = useState("");
  const [searchParams] = useSearchParams();
  const [exitPrivacy, setExitPrivacy] = useState(false);
  const [exitTerms, setExitTerms] = useState(false);
  const { category } = useParams();

  const isZymfentra = category === "zymfentra";

  useEffect(() => {
    const passedRole = searchParams.get("role");
    if (passedRole) {
      setRole(passedRole);
    }
  }, [role]);

  const roleName = role.charAt(0).toUpperCase() + role.slice(1);

  const [insuranceTypeLabel, setInsuranceTypeLabel] = useState("");

  useEffect(() => {
    const value = category;
    if(isPatient)
      {
        switch(value) {
          case 'zymfentra':
            setInsuranceTypeLabel(constants.INSURANCE_TYPE);
            break;
          case 'yuflyma':
            setInsuranceTypeLabel(constants.INSURANCE_TYPE);         
            break;
          case 'vegzelma':
            setInsuranceTypeLabel(constants.INSURANCE_TYPE_OTHER);                 
            break;
          default:
            setInsuranceTypeLabel(constants.INSURANCE_TYPE);   
            break;
        }
      }
      else
      {
        switch(value) {
          case 'vegzelma':
            setInsuranceTypeLabel(constants.INSURANCE_TYPE_OTHER);                 
            break;
          default:
            setInsuranceTypeLabel(constants.INSURANCE_TYPE_OTHER);   
            break;
        }

      }          
  }, [category, isPatient]);
  
  const [residencyLabel, setResidencyLabel] = useState("");

  useEffect(() => {
    const value = category;
    if(isPatient)
      {
        switch(value) {
          case 'zymfentra':
            setResidencyLabel(constants.PATIENT_RESIDENCY);
            break;
          case 'yuflyma':
            setResidencyLabel(constants.PATIENT_RESIDENCY_OTHER);         
            break;
          case 'vegzelma':
            setResidencyLabel(constants.PATIENT_RESIDENCY_VEGZELMA);                 
            break;
          default:
            setResidencyLabel(constants.PATIENT_RESIDENCY);   
            break;
        }
      }
      else
      {
        switch(value) {
          case 'vegzelma':
            setResidencyLabel(constants.PATIENT_RESIDENCY_VEGZELMA);                 
            break;
          default:
            setResidencyLabel(constants.PATIENT_RESIDENCY_PRESCRIBER);   
            break;
        }

      }      
  }, [category, isPatient]);

  const [ageLabel, setAgeLabel] = useState("");

  useEffect(() => {
    const value = category;
    if(isPatient)
      {
        switch(value) {
          case 'zymfentra':
            setAgeLabel(constants.AGE_LABEL);
            break;
          case 'yuflyma':
            setAgeLabel(constants.AGE_LABEL_OTHER);         
            break;
          case 'vegzelma':
            setAgeLabel(constants.AGE_LABEL_OTHER);                 
            break;
          default:
            setAgeLabel(constants.AGE_LABEL);   
            break;
        }
      }
      else
      {
        switch(value) {
          case 'vegzelma':
            setAgeLabel(constants.AGE_LABEL_OTHER);                 
            break;
          default:
            setAgeLabel(constants.AGE_LABEL_OTHER);   
            break;
        }

      }
  }, [category, isPatient]);

   const [hippaRelationshipLabel, setHippaRelationshipLabel] = useState("");

  useEffect(() => {
    const value = category;
    if(isPatient)
      {
        switch(value) {
          case 'zymfentra':
            setHippaRelationshipLabel(constants.HIPPA_RELATIONSHIP_LABEL_OTHER);
            break;
          case 'yuflyma':
            setHippaRelationshipLabel(constants.HIPPA_RELATIONSHIP_LABEL);         
            break;
          case 'vegzelma':
            setHippaRelationshipLabel(constants.HIPPA_RELATIONSHIP_LABEL);                 
            break;
          default:
            setHippaRelationshipLabel(constants.HIPPA_RELATIONSHIP_LABEL);   
            break;
        }
      }
      else
      {
        switch(value) {
          case 'vegzelma':
            setHippaRelationshipLabel(constants.HIPPA_RELATIONSHIP_LABEL);                 
            break;
          default:
            setHippaRelationshipLabel(constants.HIPPA_RELATIONSHIP_LABEL_OTHER);   
            break;
        }

      }
  }, [category, isPatient]);  

  const IndicationHandler = (e) => {
    const value = e.target.value;
    if (
      (value === "8" && category === "vegzelma") ||
      (value === "9" && category === "yuflyma")
    ) {
      setModalShow(true);
      setIndication(true);
    } else {
      setIndication(false);
    }
  };
  const InsuranceTypeHandler = (e) => {
    const value = e.target.value;
    if (value !== "2") {
      setModalShow(true);
      setInsuranceType(true);
    } else {
      setInsuranceType(false);
    }
  };
  const QountryHandler = (e) => {
    const value = e.target.value;
    if (value === "N") {
      setModalShow(true);
      setQountry(true);
    } else {
      setQountry(false);
    }
  };
  const age18Handler = (e) => {
    const value = e.target.value;
    if (value === "N") {
      setModalShow(true);
      setAgeeighteen(true);
    } else {
      setAgeeighteen(false);
    }
  };

  const PATConsent = (e) => {
    const value = e.target.value;
    if (value === "N") {
      setModalShow(true);
      setPatconsent(true);
    } else {
      setPatconsent(false);
    }
  };

  const TextOptIn = (e) => {
    const value = e.target.value;
    if (value === "N") {
      setModalShow(true);
      setTextOptIn(true);
    } else {
      setTextOptIn(false);
    }
  };

  const QHIPPACONSENT = (e) => {
    const value = e.target.value;
    if (value === "N") {
      setModalShow(true);
      setQhippaconsent(true);
    } else {
      setQhippaconsent(false);
    }
  };

  const QREADTERMS = (e) => {
    const value = e.target.value;
    if (value === "N") {
      setModalShow(true);
      setReadTerms(true);
    } else {
      setReadTerms(false);
    }
  };




  // const patConsentLabel = ``;

  // const textOptInLabel = ``;
  const hippaConsentLabel = "";

  return (
    <>
      <ExitPrivacy
        show={exitPrivacy}
        externalLink="https://www.celltrionconnect.com/patient-privacy-policy"
        onHide={() => setExitPrivacy(false)}
      />

      <ExitTerms
        show={exitTerms}
        externalLink={"https://www.celltrionconnect.com/terms-of-use/"}
        onHide={() => setExitTerms(false)}
      />

      <div className="heads-icon">
        <div className="hi-icon">
          <img src={Note} alt="notes" />
        </div>
        <div>
          <Card.Subtitle className="mb-2 text-muted">
            {roleName} Enrollment
          </Card.Subtitle>
          <Card.Subtitle className="mb-2 text-muted">Step 1 of 2</Card.Subtitle>
          <Card.Title>{isPatient ? "Let’s check your eligibility." : "Let's check your patient's eligibility" }</Card.Title>
        </div>
      </div>
      <div>
        {category === "yuflyma" ? (
          <Form.Group className="mb-4" controlId="Q_INDICATION_YUFLYMA">
            <Form.Label>
              {" "}
              {isPatient ? "Have you been" : "Has the patient been "} prescribed
              YUFLYMA
              <sup className="reg">&reg;</sup> for any one of the following
              conditions?
            </Form.Label>
            <Form.Select
              id="Q_INDICATION_YUFLYMA"
              name="Q_INDICATION_YUFLYMA"
              className={indication && "error"}
              {...register("Q_INDICATION_YUFLYMA", {
                onChange: (e) => {
                  IndicationHandler(e);
                },
              })}
            >
              <option selected disabled>
                Choose
              </option>
              <option value="1">Rheumatoid Arthritis</option>
              <option value="2">Juvenile Idiopathic Arthritis</option>
              <option value="3">Psoriatic Arthritis</option>
              <option value="4">Ankylosing Spondylitis</option>
              <option value="5">Crohn’s Disease</option>
              <option value="6">Ulcerative Colitis</option>
              <option value="7">Plaque Psoriasis</option>
              <option value="8">Hidradenitis Suppurativa</option>
              <option value="10">Uveitis</option>
              <option value="9">Other</option>
            </Form.Select>
          </Form.Group>
        ) : category === "vegzelma" ? (
          <Form.Group className="mb-4" controlId="Q_INDICATION_VEGZELMA">
            <Form.Label>
              {" "}
              Has the patient been prescribed VEGZELMA
              <sup className="reg">&reg;</sup> for any one of the following
              conditions?
            </Form.Label>
            <Form.Select
              id="Q_INDICATION_VEGZELMA"
              name="Q_INDICATION_VEGZELMA"
              className={indication && "error"}
              {...register("Q_INDICATION_VEGZELMA", {
                onChange: (e) => {
                  IndicationHandler(e);
                },
              })}
            >
              <option selected disabled>
                Choose
              </option>
              <option value="1">
                Metastatic cancer of the colon or rectum
              </option>
              <option value="2">Non-small cell lung cancer</option>
              <option value="3">Metastatic renal cell (kidney) cancer</option>
              <option value="4">Recurrent glioblastoma</option>
              <option value="5">Ovarian/fallopian tube cancer</option>
              <option value="6">Peritoneal cancer</option>
              <option value="7">Cervical cancer</option>
              <option value="8">Other</option>
            </Form.Select>
          </Form.Group>
        ) : (
          <>
            <Form.Group className="mb-4" controlId="Q_INDICATION_ZYMFENTRA">
              <Form.Label>
                {" "}
                {isPatient ? "Have you been" : "Has the patient been "}{" "}
                prescribed ZYMFENTRA
                <sup>&trade;</sup> for any one of the following conditions?
              </Form.Label>

              <Form.Select
                id="Q_INDICATION_ZYMFENTRA"
                name="Q_INDICATION_ZYMFENTRA"
                className={indication && "error"}
                {...register("Q_INDICATION_ZYMFENTRA", {
                  onChange: (e) => {
                    IndicationHandler(e);
                  },
                })}
              >
                <option selected disabled>
                  Choose
                </option>
                <option value="1">
                  Moderately to severely active Crohn’s disease following
                  treatment with an infliximab product administered
                  intravenously
                </option>
                <option value="2">
                  Moderately to severely active ulcerative colitis following
                  treatment with an infliximab product administered
                  intravenously
                </option>
              </Form.Select>
            </Form.Group>
          </>
        )}
        <Form.Group className="mb-4" controlId="Q_INSURANCE_TYPE">
          <Form.Label>{insuranceTypeLabel}</Form.Label>
          <Form.Select
            name="Q_INSURANCE_TYPE"
            id="Q_INSURANCE_TYPE"
            className={insuranceType && "error"}
            {...register("Q_INSURANCE_TYPE", {
              onChange: (e) => {
                InsuranceTypeHandler(e);
              },
            })}
          >
            <option selected disabled>
              Choose
            </option>
            <option value="1">
              Any government, state, or federally funded prescription benefit
              program. This includes Medicare, Medicaid, Medigap, VA, DoD, and
              TRICARE.
            </option>
            <option value="2">Commercially insured (private insurance)</option>
            <option value="3">
              Does not have prescription drug coverage - paying cash
            </option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-4" controlId="Q_COUNTRY">
          <Form.Label>{residencyLabel}</Form.Label>
          <div key={`inline-radio`} className="mb-3">
            <Form.Check
              required
              inline
              label="Yes"
              value="Y"
              name="Q_COUNTRY"
              type={"radio"}
              id={`Q_COUNTRY_Y`}
              {...register("Q_COUNTRY", {
                onChange: (e) => {
                  QountryHandler(e);
                },
              })}
            />
            <Form.Check
              inline
              label="No"
              value="N"
              name="Q_COUNTRY"
              className={qountry && "error"}
              type={"radio"}
              id={`Q_COUNTRY_N`}
              {...register("Q_COUNTRY", {
                onChange: (e) => {
                  QountryHandler(e);
                },
              })}
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-4" controlId="Q_AGE_18">
          <Form.Label>{ageLabel}</Form.Label>
          <div key={`inline-radio`} className="mb-3">
            <Form.Check
              required
              inline
              label="Yes"
              value="Y"
              name="Q_AGE_18"
              type={"radio"}
              id={`Q_AGE_18_Y`}
              {...register("Q_AGE_18", {
                onChange: (e) => {
                  age18Handler(e);
                },
              })}
            />
            <Form.Check
              inline
              label="No"
              value="N"
              name="Q_AGE_18"
              type={"radio"}
              className={ageeighteen && "error"}
              id={`Q_AGE_18_N`}
              {...register("Q_AGE_18", {
                onChange: (e) => {
                  age18Handler(e);
                },
              })}
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-4" controlId="Q_HIPAA_PATIENT_RELATIONSHIP">
          <Form.Label>{hippaRelationshipLabel}</Form.Label>
          <Form.Select
            className={errors.Q_HIPAA_PATIENT_RELATIONSHIP && "error"}
            name="Q_HIPAA_PATIENT_RELATIONSHIP"
            id="Q_HIPAA_PATIENT_RELATIONSHIP"
            {...register("Q_HIPAA_PATIENT_RELATIONSHIP", { required: true })}
          >
            <option selected disabled>
              Choose
            </option>
            <option value="1">Patient</option>
            <option value="2">Caregiver</option>
            <option value="3">Advocate</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-4" controlId="Q_READ_TERMS">
          <Form.Label>
            I confirm that I have read, consent, and agree to the User Privacy
            Policy{" "}
            <a href="javascript:void(0)" onClick={() => setExitPrivacy(true)}>
              www.celltrionconnect.com/patient-privacy-policy
            </a>{" "}
            and the Terms and Conditions at{" "}
            <a href="javascript:void(0)" onClick={() => setExitTerms(true)}>
              {"https://www.celltrionconnect.com/terms-of-use/"}
            </a>
            , and that I am of legal age.
          </Form.Label>
          <div key={`inline-radio`} className="mb-3">
            <Form.Check
              inline
              label="Yes"
              value="Y"
              name="Q_READ_TERMS"
              type={"radio"}
              id={`readterms-yes`}
              {...register("Q_READ_TERMS", {
                required: true,
                onChange: (e) => {
                  QREADTERMS(e);
                },
              })}
            />
            <Form.Check
              inline
              label="No"
              value="N"
              name="Q_READ_TERMS"
              type={"radio"}
              className={qreadterms && "error"}
              id={`readterms-no`}
              {...register("Q_READ_TERMS", {
                required: true,
                onChange: (e) => {
                  QREADTERMS(e);
                },
              })}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-4 eligibility" controlId="Q_HIPPA_CONSENT">
          <Form.Label>
            By signing this form, you certify that the patient gives their
            permission for their physicians, pharmacies, laboratories, and other
            healthcare providers (“Healthcare Providers”) and their health
            insurers to share their individually identifiable health information
            with Celltrion USA, Inc., the Celltrion Patient Assistance
            Foundation, Celltrion USA affiliates and its vendors (collectively,
            “Celltrion USA”). The patient understands that their individually
            identifiable health information may include their full name,
            address, date of birth, demographic information, financial
            information, insurance information and information related to
            medical condition, treatment, care management, medication history,
            and prescriptions (collectively, “Health Information”), whether in
            written or verbal form, including portions of their medical record.
            The patient’s Health Information will be shared with Celltrion USA
            so that Celltrion USA may provide them with various support and
            information to help them access a Celltrion USA medicine, which may
            include the following, depending on the program (collectively,
            “Patient Support Activities”).
          </Form.Label>
          <div key={`inline-radio`} className="mb-3">
            <Form.Check
              inline
              label="Yes"
              value="Y"
              name="Q_HIPPA_CONSENT"
              type={"radio"}
              id={`signsin-yes`}
              {...register("Q_HIPPA_CONSENT", {
                required: true,
                onChange: (e) => {
                  QHIPPACONSENT(e);
                },
              })}
            />
            <Form.Check
              inline
              label="No"
              value="N"
              name="Q_HIPPA_CONSENT"
              type={"radio"}
              className={qhippaconsent && "error"}
              id={`signsin-no`}
              {...register("Q_HIPPA_CONSENT", {
                required: true,
                onChange: (e) => {
                  QHIPPACONSENT(e);
                },
              })}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-4" controlId="Q_PAT_CONSENT">
          <Form.Label>
            {isZymfentra && (
              <>
                The patient understands that the personal information provided
                and information pertaining to using the Celltrion CARES<sup>&trade;</sup> Co-pay
                Assistance Program will be shared with Celltrion USA, its
                third-party partners and entities. The patient also authorizes
                Celltrion USA, its partners and entities to communicate with
                him/her about products, health conditions, co-pay and financial
                assistance. The patient agrees to be contacted by mail, email,
                and at the phone number provided to leave voice messages, and
                interactive voice recordings. The patient may opt out of
                individual communications of the program entirely at any time by
                calling 1-877-812-6662 (1-877-81CONNC). Celltrion USA and
                companies providing services to Celltrion USA will not sell or
                rent personally identifiable information, as described in the
                Privacy Policy, which can be viewed by visiting CelltrionCares.com.
              </>
            )}
            {(category ==="vegzelma" || (category ==="yuflyma" && roleName !=="patient")) && (
              <>
                The patient understands that the personal information provided
                and information pertaining to using the Celltrion CARES<sup>&trade;</sup> Co-pay
                Assistance Program will be shared with Celltrion USA, its
                third-party partners and entities. Does the patient also
                authorize Celltrion USA, its partners and entities to
                communicate with him/her about products, health conditions,
                co-pay and financial assistance. The patient agrees to be
                contacted by mail, email, and at the phone number provided to
                leave voice messages, and interactive voice recordings. The
                patient may opt out of individual communications of the program
                entirely at any time by calling 1-877-812-6662 (1-877-81CONNC),
                or by clicking on the "unsubscribe" link. Celltrion USA and
                companies providing services to Celltrion USA will not sell or
                rent personally identifiable information, as described in the
                Privacy Policy, which can be viewed by visiting <a target="_blank" href="https://www.celltrionconnect.com" rel="noreferrer">www.celltrionconnect.com</a>.</>
            )}
            {category ==="yuflyma" && roleName ==="patient" && (
              <>
                Do you understand that the personal information provided and information
                pertaining to using the Celltrion CARES<sup>&trade;</sup> Co-pay Assistance Program will be
                shared with Celltrion, its third-party partners and entities.
                Do you also authorize Celltrion, its partners and entities to communicate with
                him/her about products, health conditions, co-pay, and financial assistance.
                The patient agrees to be contacted by mail, email, and at the phone number
                provided to leave voice messages, and interactive voice recordings. The
                patient may opt out of individual communications of the program entirely at
                any time by calling 1-877-812-6662 (1-877-81CONNC), or by clicking on the
                "unsubscribe" link. Celltrion and companies providing services to Celltrion will
                not sell or rent personally identifiable information, as described in the Privacy
                Policy, which can be viewed by visiting <a target="_blank" href="https://www.celltrionconnect.com" rel="noreferrer">www.celltrionconnect.com</a>.
              </>
            )}            
          </Form.Label>
          <div key={`inline-radio`} className="mb-3">
            <Form.Check
              required
              inline
              label="Agree"
              value="Y"
              name="Q_PAT_CONSENT"
              type={"radio"}
              id={`patientn-yes`}
              {...register("Q_PAT_CONSENT", {
                required: true,
                onChange: (e) => {
                  PATConsent(e);
                },
              })}
            />
            <Form.Check
              inline
              label="Disagree"
              value="N"
              name="Q_PAT_CONSENT"
              type={"radio"}
              className={patconsent && "error"}
              id={`patientn-no`}
              {...register("Q_PAT_CONSENT", {
                required: true,
                onChange: (e) => {
                  PATConsent(e);
                },
              })}
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-4" controlId="Q_TEXT_OPT_IN">
          <Form.Label>
            {category === "vegzelma" && (
              <>
                As part of the Celltrion CARES<sup>&trade;</sup> Co-pay
                Assistance Program, the patient understands and agrees to
                receive autodialed text messages from Celltrion USA, any
                companies working with Celltrion USA and McKesson entities that
                support this program, which may include refill reminders and
                other offers. The patient understands he/she is not required to
                agree to receive text messages as a condition of this savings
                offer, that message and data rates may apply, and that these
                text messages will reoccur monthly. The patient may text 'STOP'
                to opt out at any time. For help at any time, text 'HELP'. For
                additional support call 1-877-812-6662 (1-877-81CONNC).
                Participating Carriers: All U.S. Based Carriers. Messages may be
                delayed or undelivered for various factors. McKesson, carriers
                (including, but not limited to, T-Mobile) and any service
                providers utilized by McKesson to send messages are not liable
                for delayed or undelivered messages.
              </>
            )}

            {category === "zymfentra" && (
              <>
                As part of the Celltrion CARES<sup>&trade;</sup> Co-pay
                Assistance, the patient understands and agrees to receive
                autodialed text messages from Celltrion USA, any companies
                working with Celltrion USA and Paysign entities that support
                this program, which may include refill reminders and other
                offers. The patient understands he/she is not required to agree
                to receive text messages as a condition of this savings offer,
                that message and data rates may apply, and that these text
                messages will reoccur monthly. The patient may text 'STOP' to
                opt out at any time. For help at any time, text 'HELP'. For
                additional support call 1-877-812-6662 (1-877-81CONNC).
                Participating Carriers: All U.S. Based Carriers. Messages may be
                delayed or undelivered for various factors. Carriers and any
                service providers utilized to send messages are not liable for
                delayed or undelivered messages.
              </>
            )}

            {category === "yuflyma" && (
              <>
              By signing up for text messages from Celltrion, the patient agrees that they
              are the primary owner of the phone number provided and consent to
              receiving promotional communications in the form of phone calls or text
              messages relating to Celltrion products and services and/or their condition or
              treatment. Messages may be sent from an automated system. Consent is not
              required for the purchase of any goods or services. Message and Data Rates
              May Apply. Unsubscribe at any time by replying STOP or clicking the
              unsubscribe link (where available). Text HELP for help. Message frequency
              varies. To the maximum extent permitted by law: (i) all information contained
              in SMS text messages is provided “as is” without warranty of any kind, either
              express or implied, including, but not limited to, the implied warranties of
              merchantability, fitness for a particular purpose, or non-infringement; and (ii)
              Celltrion expressly excludes any liability for any direct, indirect, or
              consequential loss or damage incurred by any user in connection with the
              receipt, use, failure of, or inability to use, SMS text messages.
              <br></br>
              <br></br>
              The patient also gives their permission to receive communications from
              Celltrion and parties acting on its behalf, including calls made with an
              autodialer or prerecorded voice at the phone number(s) provided to
              determine their eligibility and provide benefits verification, prior
              authorization/appeals assistance, and financial assistance resources and
              information, such as co-pay support or free drug programs, and for other
              non-marketing purposes. The patient understands that they can opt-out of
              these telephonic communications concerning Patient Support Activities at
              any time by contacting Celltrion at 1-877-81CONNC (1-877-812-6662), Monday
              - Friday, 8 AM - 8 PM ET, or in writing at PO BOX 610 Columbus, OH 43216.
              Celltrion CONNECT<sup>&reg;</sup>: View our privacy policy: <a target="_blank" href="https://www.celltrionconnect.com/patient-privacy-policy" rel="noreferrer">www.celltrionconnect.com/patient-privacy-policy</a> | View our terms of use: <a target="_blank" href="https://www.celltrionconnect.com/terms-of-use" rel="noreferrer">wwww.celltrionconnect.com/terms-of-use</a>
              </>
            )}            
          </Form.Label>
          <div key={`inline-radio`} className="mb-3">
            <Form.Check
              required
              inline
              label="Agree"
              value="Y"
              name="Q_TEXT_OPT_IN"
              type={"radio"}
              id={`cares-yes`}
              {...register("Q_TEXT_OPT_IN", {
                required: true,
              })}
            />
            <Form.Check
              inline
              label="Disagree"
              value="N"
              name="Q_TEXT_OPT_IN"
              type={"radio"}
              className={textOptIn}
              id={`cares-no`}
              {...register("Q_TEXT_OPT_IN", {
                required: true,
              })}
            />
          </div>
        </Form.Group>
      </div>

      <div className="form-buttons">
        <div className="s2-button">
          <Button
            className="theme-button theme-button-primary"
            onClick={nextStep}
            disabled={
              indication ||
              insuranceType ||
              qountry ||
              ageeighteen ||
              patconsent ||
              textOptIn ||
              qhippaconsent ||
              qreadterms
            }
          >
            Next
          </Button>
        </div>
        <Button
          onClick={handleCancel}
          variant="light"
          className="theme-button theme-button-text"
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default Eligibility;
